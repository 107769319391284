<template>
  <div class="container">
    <div class="top">
		
		<div style="width: 420px;
    height: 286px;
	background: white; z-index: 9999; margin-left: 16%;
    margin-top: 8%;position:absolute;border-radius: 25px;"
	
	>
	
			<div style="margin-top:	40px;   ">
				<p style="    font-size: 19px;
			font-weight: 500;
			font-style: oblique;   padding: 20px 10px;">助力站疫，居家问诊</p>
				<p  style=" padding: 20px 10px; font-size: 19px; font-weight: 800;">专业的医疗咨询，优质的医生资源</p>
				<p 	v-if="!uid" style="padding: 5px 0;text-align:center"><button @click="handleRegister" style="width: 192px;" data-v-04ded668="" type="button" class="el-button el-button--default el-button--small"><!----><!----><span>免费注册</span></button></p>
				<p 	v-if="!uid" 	 style="padding: 15px 0;text-align:center;color: red;cursor:pointer" @click="handleLogin"   >  我是会员，立即登录 </p>
			</div>
				  
		</div>
		
		
		
      <el-carousel class="poster" height="521px">
        <el-carousel-item v-for="i in 4" :key="i">
          <el-image :src="require('@/assets/images/poster.png')" alt="" style="width:100%;height: 521px;" fit="cover"/>
        </el-carousel-item>
      </el-carousel> 
	  

	  
	  
	  
      <el-card class="notice">
        <el-carousel indicator-position="none" arrow="never" direction="horizontal" :interval="5000">
          <el-carousel-item v-for="i in 4" :key="i" class="notice_item">
            <img src="@/assets/images/notice.png" alt="">
            医疗公告！医疗公告！医疗公告！医疗公告！{{i}}
          </el-carousel-item>
        </el-carousel>
      </el-card>
    </div>
    <div class="content">
      <div class="header">
        <div class="title">搜询医生
          <small>以下为综合自动排序，排名不分先后</small>
        </div>

        <div class="nav">
			
            <el-link v-for="(item,index) in types" :key="index" class="nav_link"
                   :class="{active: searchActiveIndex === index}"
                   @click='getHomeList(index)'
				   >
				{{item.name}}
            </el-link>
		  
		  
        </div>
		
		

        <div class="btns">
          <el-button type="text" size="mini" @click="getPaidList">换一组&nbsp;<i class="el-icon-refresh el-icon--right"/>
          </el-button>
          <el-button type="text" size="mini" @click="toMore">更多&nbsp;<i class="el-icon-arrow-right el-icon--right"/>
          </el-button>
        </div>
      </div>
	  
	  
      <div class="body clearFix">
        <div class="body_left">
			
			
        <template v-for="(item,index) in apidoctors">
            <DocCard :info="item" :key="index" :class="{active: docIndex === index}" @btn-click="handleDoc(item.id)"  @goPay="go_pay(item.id)"/>
        </template> 
		  
		  
        </div> 
		
        <div class="body_right" style="height: 100px;">
          <h3>排名提前</h3>
          <div class="top5">
            <template v-for="(item,index) in top">
              <SideCard :info="item" :key="index" :class="{active: topIndex === index}"
                        @btn-click="handleTop(item.id)"/>
            </template>
          </div>
        </div>  
		
		
		
      </div> 
	   
	  
	  
    </div>
	
<!-- 	<div    v-if="visible" id="pays" style="z-index99999;position: fixed; top:150px;left:580px; background-color: white; width: 300px;height:300px;">
		
		<p style="text-align: center; margin-top: 20px;" >请扫码支付20元咨询费</p>
		<p style="text-align: center;margin-top: 20px;"><img style="width: 125px;" src="../../assets/images/weixin.png"></p>
 
		<p style="text-align: center;margin-top: 20px;"><a style=" text-decoration:none; color:#3BD1DC"  href="javascript:void(0)" @click="close_pay()"  >关闭</a></p>
		
	</div>
	
 -->
	
	
	<pay-img-dialog ref="payImg"></pay-img-dialog>
  
  <Gifts ref="gifts"  />
  </div>
</template>

<script>
	
  import Gifts from '@/components/Gifts'
  import axios from "axios";
  import doctors from '@/data.js'
  import DocCard from '@/components/DocCard'
  import SideCard from '@/components/SideCard'
  import {getPaidRankList, getSysRankedList} from "../../api/DoctorApi";
  import PayImg from "../children/service/components/PayImgDialog.vue";
  
  import {mapState} from 'vuex';

  export default {
    name: "HomeIndex",
    components: {DocCard, SideCard,PayImg,Gifts},
    data() {
      return {
		types:[{name:1,id:1},{name:2,id:2}],
		uid:sessionStorage.getItem("uid"),
        searchActiveIndex: 0,
        doctors: doctors,
		apidoctors:[],
		apidoctors2: [ { name:"323", url: require('../../assets/images/d1.jpg'),} ], 
        topIndex: 0,
        docIndex: null,
        paidList: [],
		top:[],
		 visible:false,
	 
		tabType: 0,
		tabs: [
		  {name: "账号密码登录", type: 0}, {name: "手机号码登录", type: 1}
		],
		loginForm: {},
		btnSMS: '发送验证码',
		btnSMSDisabled: false,
		
		rules: {
		  loginName: [
		    {required: true, message: "账号不能为空", trigger: 'blur'}
		  ],
		  password: [
		    {required: true, message: "密码不能为空", trigger: 'blur'},
		  ],
		},
		
      }
    },
    computed: {
     // ...mapState(["types"])
    },

    created() {  
		const params = new URLSearchParams();
		params.append('uid', sessionStorage.getItem('uid')); 
		console.log(params);
		axios.post(this.apiUrl+'/api/home/index',params)
		.then((response) => {  
			console.log(response.data.data); 
			this.apidoctors = response.data.data.doctor_list;
			this.types = response.data.data.department_list;
			console.log(1111111111111111111112222222222222)
 
					  
		})
		
		
		  .catch(function(error) {
		    console.log(error);
		}); 
		
		
			const params2 = new URLSearchParams();
			params2.append('uid', sessionStorage.getItem('uid')); 
		 
			axios.post(this.apiUrl+'/api/home/top',params2)
			.then((response) => {  
				console.log(response.data.data); 
				this.top = response.data.data; 
						  
			})
			
			
			  .catch(function(error) {
			    console.log(error);
			}); 
			
			
	},
    mounted() {
      this.getHomeList(0);
      this.getPaidList();
    },

    methods: {
		close_pay(){
			this.visible = false;
		},
	go_pay(){ 
			this.visible = true;
	},
		
		go_news(){ 
			 this.$router.push({name: '资讯'})	
		},
		
		go_goods(name){ 
			
			this.$router.push({name:name})	
		
		},
		
		
		
		handleLogin() {
		  this.$router.push({name: '登录页面'})
		},
		handleRegister() {
		  this.$router.push({name: '注册账号'})
		},

      handleDoc(index) {
        this.docIndex = index;
        this.$router.push({path: `/doctor/aa?id=${index}`})
      },

      handleTop(index) {
        this.topIndex = index;
        this.$router.push({path: `/doctor/aa?id=${index}`})
      },

      /**
       * 获取系统自动排序的医生列表
       */
      getHomeList(index) {
        this.searchActiveIndex = index;
        getSysRankedList({type: this.types[index]}).then(arr => {
          // this.doctors = arr;
          console.log('获取系统自动排序的医生列表', arr);
        })
      },

      /**
       * 获取付费排序的医生列表
       */
      getPaidList() {
        getPaidRankList().then(arr => {
          console.log('获取付费排序的医生列表', arr);
        })
      },

      /**
       * 打开搜索医生页面
       */
      toMore() {
        this.$router.push({"name": "搜询医生"})
      },

    }
  }
</script>

<style scoped>
	
	.lis{
		 list-style-type:none; 
		 float: left; 
		 /* background-color: red; */
		 width: 260px; 
		 height:276px;
		 margin-right:36px;
		 margin-bottom: 30px;;
	}
	
  .container {
    width: 100%;
  }

  .top {
    position: relative;
    width: 100%;
  }

  /*海报轮播*/
  .poster .el-carousel__item {
    /*background-image: url('../../assets/images/poster.png');*/
    /*background-position: center center;*/
  }

  /deep/ .el-carousel__arrow {
    height: 140px;
    width: 60px;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, .31);
    font-size: 48px;
  }

  /deep/ .el-carousel__arrow--left {
    left: 106px;
  }

  /deep/ .el-carousel__arrow--right {
    right: 152px;
  }

  /*公告*/
  .notice {
    width: 1200px;
    height: 50px;
    left: 50%;
    transform: translate(-50%);
    bottom: -25px;
    position: absolute;
    z-index: 2;
    padding: 0;
  }

  .notice /deep/ .el-card__body {
    padding: 0;
  }

  .notice_item {
    height: 50px;
    line-height: 50px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .notice_item img {
    width: 40px;
    /*height: 16px;*/
    margin: 0 44px 0 32px;
  }

  .content {
    width: 1200px;
    margin: 75px auto;
    letter-spacing: 2px;
  }

  /*小导航条*/
  .content .header {
    position: relative;
    display: flex;
    /*align-items: baseline;*/
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 32px;
  }

  /*小导航条标题*/
  .content .header .title {
    font-size: 30px;
    font-weight: bold;
  }

  .content .header .title small {
    /*float: left;*/
    font-size: 10px;
    padding: 0 10px;
    color: #A7A7A7;
    font-weight: 500;
  }

  /*小导航条导航*/
  .content .header .nav {
    padding: 0 5px;
  }

  .content .header .nav .nav_link {
    padding: 0 15px;
    /*margin: 0 5px;*/
    height: 28px;
    line-height: 28px;
    font-size: 16px;
    font-weight: 500;
	float: left;
  }

  .content .header .nav .nav_link.active {
    background-color: #33D0DC;
    color: white;
  }

  /deep/ .el-link.el-link--default {
    color: #333333;
  }

  /deep/ .el-link.is-underline:hover:after {
    border-bottom: unset;
  }

  /*小导航条更多*/
  .content .header .btns {
    font-size: 16px;
    margin-left: 70px;
  }

  /deep/ .el-button--mini, .el-button--small {
    font-size: 16px;
  }

  /deep/ .el-button--mini, .el-button--mini.is-round {
    padding: 5px;
  }

  /deep/ .el-button--text {
    color: #727272;
  }

  .content .body .body_left {
    width: 80%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    float: left;
  }
</style>
<style scoped lang="scss">
  .content {
    .body {
      .body_right {
        width: calc(20% - 20px);
        float: right;
        background-color: #fff;
        height: 100%;

        h3 {
          color: $red;
          text-align: center;
          margin: 30px 0;
        }

        .top5 {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-bottom: 10px;
          // height: 1660px;
          justify-content: space-between;
        }
      }
    }
  }
</style>
<style scoped>
  .content_from {
    width: 400px;
    height: 320px;
    position: relative;
    left: calc(1200px - 450px);
    top: 120px;
    background: #fff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 10px;
    padding: 25px;
  }

  .header {
   /* padding: 0px 50px; */
  }

  .header h4 {
    text-align: center;
    cursor: pointer;
  }

  .header .active {
    display: block;
    width: max-content;
    padding-bottom: 10px;
    border-bottom: 3px solid #3CD0DC;
    color: #3CD0DC;
    margin: auto;
  }

  .form {
    padding: 0px 50px;
  }

  .el-checkbox.is-bordered {
    padding-left: 0px !important;
    border: unset;
  }

  .text-right {
    text-align: right;
  }

  .text-center {
    text-align: center;
  }

  .btn-login {
    width: 100%;
  }

  .el-button--text {
    color: #3BD1DC !important;
  }
</style>

